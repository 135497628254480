import * as React from "react";
import { useRef, useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { AnimatePresence, motion } from "framer-motion";
import { useClickAway } from "react-use";
import { AiOutlineRollback } from "react-icons/ai";
import { CgLogOut } from "react-icons/cg";
import { FiShoppingCart, FiHome } from "react-icons/fi";
import { ObsidianButton } from "./obsidianButtons";
const Navbar = (props) => {
    const [open, setOpen] = useState(false);
    const ref = useRef(null);
    useClickAway(ref, () => setOpen(false));
    const toggleSidebar = () => setOpen((prev) => !prev);
    const items = [
        {
            title: "Website",
            Icon: FiHome,
            href: "https://obsidiantears.xyz",
        },
        {
            title: "Buy NFT",
            Icon: FiShoppingCart,
            href: "https://entrepot.app/marketplace/obsidian-tears",
        },
        {
            title: "Logout",
            Icon: CgLogOut,
            clickCallback: async () => await props.logout(),
        },
    ];
    return (React.createElement("nav", { className: "flex justify-start w-full py-4 px-10 overflow-hidden" },
        React.createElement("img", { alt: "logo", src: "header-logo.png", className: "w-10 h-10 md:w-20 md:h-20" }),
        React.createElement("img", { alt: "big-logo", src: "menu-big-logo.png", className: "w-40 h-10 md:w-96 md:h-20" }),
        React.createElement("button", { onClick: toggleSidebar, className: "last-of-type:ml-auto lg:hidden", "aria-label": "toggle sidebar" },
            React.createElement(GiHamburgerMenu, { className: "text-white", size: 30 })),
        React.createElement(AnimatePresence, { mode: "wait", initial: false }, open && (React.createElement(React.Fragment, null,
            React.createElement(motion.div, { ...framerSidebarPanel, className: "fixed top-0 bottom-0 right-0 z-50 w-full h-screen max-w-xs border-l-2 border-white bg-regal-blue", ref: ref, "aria-label": "Sidebar" },
                React.createElement("div", { className: "flex items-center justify-between p-5 border-b-2 border-white text-white bg-regal-blue" },
                    React.createElement("span", { className: "text-white font-mochiy" }, "Menu"),
                    React.createElement("button", { onClick: toggleSidebar, className: "p-3 border-2 border-white rounded-xl text-white", "aria-label": "close sidebar" },
                        React.createElement(AiOutlineRollback, null))),
                React.createElement("ul", null, items.map((item, idx) => {
                    const { title, href, Icon, clickCallback } = item;
                    if (item.title === "Logout" && !props.logout)
                        return;
                    return (React.createElement("li", { key: title },
                        React.createElement("a", { onClick: clickCallback
                                ? toggleSidebar && clickCallback
                                : toggleSidebar, href: href, className: "flex items-center justify-between gap-5 p-5 transition-all border-b-2 hover:bg-zinc-900 border-white text-white font-mochiy" },
                            React.createElement(motion.span, { ...framerText(idx) }, title),
                            React.createElement(motion.div, { ...framerIcon },
                                React.createElement(Icon, { className: "text-2xl" })))));
                })))))),
        React.createElement("div", { className: "hidden lg:flex lg:items-center ml-auto" },
            React.createElement(ObsidianButton, { buttonText: "Website", clickCallback: () => window.open("https://obsidiantears.xyz") }),
            React.createElement(ObsidianButton, { buttonText: "Buy NFT", clickCallback: () => window.open("https://entrepot.app/marketplace/obsidian-tears"), extraClasses: "ml-5" }),
            props.logout && (React.createElement("div", { className: "float-right" },
                React.createElement(ObsidianButton, { buttonText: "Logout", clickCallback: async () => await props.logout(), extraClasses: "ml-5" }))))));
};
const framerSidebarPanel = {
    initial: { x: "100%" },
    animate: { x: 0 },
    exit: { x: "100%" },
    transition: { duration: 0.3 },
};
const framerText = (delay) => {
    return {
        initial: { opacity: 0, x: -50 },
        animate: { opacity: 1, x: 0 },
        transition: {
            delay: 0.5 + delay / 10,
        },
    };
};
const framerIcon = {
    initial: { scale: 0 },
    animate: { scale: 1 },
    transition: {
        type: "spring",
        stiffness: 260,
        damping: 20,
        delay: 1.5,
    },
};
export default Navbar;
