import * as React from "react";
const LoginErrorCard = () => {
    return (React.createElement("div", { className: "w-full flex justify-center" },
        React.createElement("div", { className: "w-5/6 lg:w-1/2 max-w-96 h-full" },
            React.createElement("div", { className: "bg-red-500 text-white font-bold rounded-t px-4 py-2 flex flex-col" }, "Unexpected Login Error"),
            React.createElement("div", { className: "border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-left text-red-700" },
                React.createElement("p", null, "An unexpected error ocurred while trying to fetch your NFTs."),
                React.createElement("br", null),
                React.createElement("p", null,
                    "Kindly reach out to our team in",
                    " ",
                    React.createElement("a", { href: "https://discord.gg/YSVjpTtGHq", target: "_blank", className: "text-blue-500 underline", rel: "noreferrer" }, "Discord"),
                    " ",
                    "for support.")))));
};
export default LoginErrorCard;
