import * as React from "react";
const StoicErrorCard = () => {
    return (React.createElement("div", { className: "w-full flex justify-center" },
        React.createElement("div", { className: "w-5/6 lg:w-1/2 max-w-96 h-full" },
            React.createElement("div", { className: "bg-red-500 text-white font-bold rounded-t px-4 py-2 flex flex-col" }, "There was an error with Stoic Wallet"),
            React.createElement("div", { className: "border border-t-0 border-red-400 rounded-b bg-red-100 px-4 py-3 text-left text-red-700" },
                React.createElement("p", null, "The most probable cause is due to a new feature in Chrome that is incompatible with Stoic Wallet."),
                React.createElement("br", null),
                React.createElement("p", null,
                    "To fix this issue, follow the steps mention on their",
                    " ",
                    React.createElement("a", { href: "https://x.com/stoicwalletapp/status/1706317772194517482?s=46&t=4XqsIm2zxxeH9ADUYAWcfQ", target: "_blank", className: "text-blue-500 underline", rel: "noreferrer" }, "X post"),
                    ":",
                    " "),
                React.createElement("ul", null,
                    React.createElement("li", null, "1. Open a new tab."),
                    React.createElement("li", null,
                        "2. Go to",
                        " ",
                        React.createElement("strong", null,
                            React.createElement("i", null, "chrome://flags/#third-party-storage-partitioning")),
                        "."),
                    React.createElement("li", null, "3. Disable the feature and restart your browser."))))));
};
export default StoicErrorCard;
